import axios from 'axios';
import qs from 'qs';

const BASE_URL = "";
let logoutHandler = null;

function generateUrl(url, formParams) {
    let paramString = qs.stringify(formParams);
    if (paramString != "") {
        paramString = "?" + paramString;
    }
    return url + paramString;
}

function callLogout() {
    if (logoutHandler != null) {
        logoutHandler();
    }
}

const mtop = {
    absoluteUrl: (url) => {
        return BASE_URL + url;
    },
    setLogoutHandler: (handler) => {
        logoutHandler = handler;
    },
    get: async (url, formParams) => {
        try {
            let response = await axios.get(generateUrl(BASE_URL + url, formParams));
            if (response.data.code == "NOT_LOGIN") {
                callLogout();
            }
            return response.data;
        } catch (error) {
            return {
                success: false,
                code: 'NET_ERROR',
                desc: error.message
            }
        }
    },
    post: async (url, formValues) => {
        try {
            let response = await axios.post(BASE_URL + url, formValues);
            if (response.data.code == "NOT_LOGIN") {
                callLogout();
            }
            return response.data;
        } catch (error) {
            return {
                success: false,
                code: 'NET_ERROR',
                desc: error.message
            }
        }
    },
    put: async (url, formValues) => {
        try {
            let response = await axios.put(BASE_URL + url, formValues);
            if (response.data.code == "NOT_LOGIN") {
                callLogout();
            }
            return response.data;
        } catch (error) {
            return {
                success: false,
                code: 'NET_ERROR',
                desc: error.message
            }
        }
    },
    delete: async (url, formParams) => {
        try {
            let response = await axios.delete(generateUrl(BASE_URL + url, formParams));
            if (response.data.code == "NOT_LOGIN") {
                callLogout();
            }
            return response.data;
        } catch (error) {
            return {
                success: false,
                code: 'NET_ERROR',
                desc: error.message
            }
        }
    }
}

export default mtop;
