import ProLayout, { PageContainer, SettingDrawer } from '@ant-design/pro-layout';
import { Link, useLocation,useHistory } from 'react-router-dom';
import { Avatar, Menu, Dropdown ,Modal} from 'antd';
import {useAuth} from '../auth/AuthContext';
// 图标请参考：https://ant.design/components/icon-cn/
import { SmileOutlined, SettingOutlined, MoneyCollectOutlined, UserOutlined,
    FileOutlined,UserSwitchOutlined,GiftOutlined,AlipayOutlined,ShareAltOutlined} from '@ant-design/icons';

const route = {
    path: '/',
    routes: [
        {
            icon: <SmileOutlined />,
            name: '欢迎',
            path: '/'
        },
        {
            icon: <UserSwitchOutlined />,
            name: '代理管理',
            children:[
                {
                    icon: <UserSwitchOutlined />,
                    name: 'HTTP',
                    path: '/proxy/http',
                },
                {
                    icon: <UserSwitchOutlined />,
                    name: '通用端口',
                    path: '/proxy/port',
                }
            ]
        },
        {
            icon: <UserSwitchOutlined />,
            name: '隧道管理',
            path: '/frp'
        }
    ]
}

function filterRoute(route,auth){
    if(route.rights!=null){
        if(!auth.haveRights(route.rights)) {
            return null;
        }
    }

    let subRoutes=null;
    if(route.routes!=null){
        subRoutes=[];
        for(let i=0;i<route.routes.length;i++){
            let subRoute = route.routes[i];
            let fr = filterRoute(subRoute,auth);
            if(fr!=null){
                subRoutes.push(fr);
            }
        }
        if(subRoutes.length==0) {
            return null;
        }
    }

    let ret={
        ...route,
        routes:subRoutes
    };

    return ret;
}

export default function MainLayout({showContainer=true,children}) {
    const location = useLocation();
    const history=useHistory();
    const auth = useAuth();

    const onClickLogout=()=>{
        Modal.confirm({
            title:'退出登录',
            content:'确认要退出当前的账号吗',
            onOk:()=>{
                auth.logout();
            }
        })
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/profile">修改资料</Link>
            </Menu.Item>
            <Menu.Item onClick={onClickLogout}>
                退出登录
            </Menu.Item>
        </Menu>
    );

    return (
        <ProLayout
            title="SNGINX"
            location={location}
            route={filterRoute(route,auth)}
            menuItemRender={
                (item, dom) => (
                    <a href="javascript:void(0);" onClick={()=>{
                        history.replace("/loading");
                        setTimeout(()=>{
                            history.replace(item.path);
                        },1);
                    }}>{dom}</a>
                )
            }
            rightContentRender={() => (
                <div>
                    <Dropdown overlay={menu}>
                        <Avatar shape="square" size="small" icon={<UserOutlined />} />
                    </Dropdown>
                </div>
            )}
        >
            {
                showContainer?(
                    <PageContainer>
                        {children}
                    </PageContainer>
                ):(
                    children
                )
            }
            
        </ProLayout>
    )
}