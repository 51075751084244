import { useAuth } from './AuthContext';
import {Skeleton} from 'antd';
import {Redirect} from 'react-router-dom';
import { useEffect, useState } from 'react';
export default function AuthChecker(props) {
    const [checking,setChecking]=useState(true);
    const auth = useAuth();
    useEffect(()=>{
        if(auth.user==null){
            auth.check();
        } else {
            setChecking(false);
        }
    },[])
    // 如果已经登录
    return (
        <>
        {
            auth.user==null?(<Skeleton/>):props.children
        }
        </>
    )
}
