import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import mtop from '../util/mtop';
import './login.css';

function LoginLayout(props) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onFinish = (values) => {
    setLoading(true);
    mtop.get("/gateway/session/login", values)
      .then((data) => {
        setLoading(false);
        if (data.success) {
          message.info({content:"登录成功",style:{marginTop:"45vh"}});
          history.push("/");
        } else {
          message.error({content:data.desc,style:{marginTop:"45vh"}});
        }
      })
  };
  return (
    <div className="login-bg" style={{ height: '100vh' }}>
      <div>
        <div>

        </div>
        <div>
          <h1>账户密码登录</h1>
        </div>
        <div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: '请输入用户名!' }]}
            >
              <Input autoComplete="off" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="密码"
              />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default LoginLayout;
