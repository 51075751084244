import ProTable from '@ant-design/pro-table';
import { Button, message, Modal, Form, Input, Space, Select, Tag, InputNumber } from 'antd';
import mtop from '../util/mtop';
import { PlusOutlined } from '@ant-design/icons';
import { useRef, useState } from 'react';


export default function ProxyHttpPage(props) {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: '请求域名',
            dataIndex: 'reqHost'
        },
        {
            title: '代理协议',
            dataIndex: 'proxyType'
        },
        {
            title: '代理域名',
            dataIndex: 'proxyHost'
        },
        {
            title: '代理端口',
            dataIndex: 'proxyPort'
        },
        {
            title: 'Host传递策略',
            render: (record)=> record.useProxyHost?"使用代理域名":"使用请求域名"
        },
        {
            title: 'FRP Id',
            dataIndex: 'frpId'
        },
        {
            title: '操作',
            render: (record) => (
                <Space>
                    <a onClick={() => { onClickEditButton(record) }}>编辑</a>
                </Space>
            )
        }
    ]
    const requestData = async (params, sort, filter) => {
        let data = await mtop.get("/gateway/proxy/http", params)
        if (data.success) {
            return {
                sucess: true,
                total: data.model.total,
                data: data.model.data.map((v) => ({
                    ...v,
                    key: v.id
                }))
            }
        } else {
            message.error({content:data.desc,style:{marginTop:"45vh"}});
        }
    }
    const [dialogVisible, setDialogVisible] = useState(false);
    const [curItem, setCurItem] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);

    const tableRef = useRef();


    const onFormFinish = (values) => {
        console.log(values);
        let promise = null
        if (curItem == null) {
            promise = mtop.put("/gateway/proxy/http", values)
        } else {
            promise = mtop.post("/gateway/proxy/http", {
                ...values,
                id: curItem.id
            })
        }
        promise.then((data) => {
            if (data.success) {
                setDialogVisible(false);
                if (curItem == null) {
                    message.info({content:"创建成功",style:{marginTop:"45vh"}});
                } else {
                    message.info({content:"更新成功",style:{marginTop:"45vh"}});
                }
                tableRef.current.reload();
            } else {
                message.error({content:data.desc,style:{marginTop:"45vh"}});
            }
        })
    }

    const onClickCreateButton = () => {
        setCurItem(null);
        setDialogVisible(true);
    }
    const onClickEditButton = (record) => {
        setCurItem(record);
        setDialogVisible(true);
    }
    const onClickDeleteButton = () => {
        Modal.confirm({
            title: '确认删除',
            content: "删除操作无法恢复，确认要删除选中的" + selectedIds.length + "条记录吗？",
            onOk: () => {
                mtop.delete("/gateway/proxy/http", { ids: selectedIds.join(',') })
                    .then((data) => {
                        if (data.success) {
                            message.info({content:"删除成功",style:{marginTop:"45vh"}});
                            tableRef.current.reload();
                            tableRef.current.clearSelected();
                        } else {
                            message.error({content:data.desc,style:{marginTop:"45vh"}});
                        }
                    })
            }
        })
    }
    const onSelectionChange = (keys, rows) => {
        setSelectedIds(keys);
    }
    return (
        <div>
            <ProTable
                headerTitle="代理管理"
                actionRef={tableRef}
                rowSelection={{
                    type: 'checkbox',
                    onChange: onSelectionChange
                }}
                columns={columns}
                request={requestData}
                search={false}
                toolBarRender={() => [
                    selectedIds.length > 0 &&
                    <Button onClick={onClickDeleteButton}>
                        删除选中({selectedIds.length})
                    </Button>,
                    <Button type="primary" onClick={onClickCreateButton}>
                        <PlusOutlined />
                        创建
                    </Button>
                ]}
            >
            </ProTable>
            <Modal style={{top:270}}
                visible={dialogVisible}
                destroyOnClose={true}
                onCancel={() => { setDialogVisible(false); }}
                title={curItem == null ? "创建" : "编辑"}
                footer={null}
                maskClosable={false}
            >
                <Form labelCol={{ span: 4 }} onFinish={onFormFinish} autoComplete="off" initialValues={curItem}>
                    <Form.Item label="请求域名" rules={[{ required: true }]} name="reqHost">
                        <Input />
                    </Form.Item>
                    <Form.Item label="代理协议" rules={[{ required: true }]} name="proxyType">
                        <Select>
                            <Select.Option value={"https"}>https</Select.Option>
                            <Select.Option value={"http"}>http</Select.Option>
                            <Select.Option value={"static"}>static</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="代理域名" rules={[{ required: true }]} name="proxyHost">
                        <Input />
                    </Form.Item>
                    <Form.Item label="代理端口" rules={[{ required: true }]} name="proxyPort">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label="Host传递策略" rules={[{ required: true }]} name="useProxyHost">
                        <Select>
                            <Select.Option value={true}>使用代理域名</Select.Option>
                            <Select.Option value={false}>使用请求域名</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="FRP ID" rules={[{ required: true }]} name="frpId">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">确定</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}