import { useState, useEffect, createContext, useContext } from 'react';
import ProSkeleton from '@ant-design/pro-skeleton';
import mtop from '../util/mtop';
import {useHistory} from 'react-router-dom';
import { message } from 'antd';

const Context = createContext(null);

function haveRights(expression,rights) {
    if(expression===null || expression==="" || expression===undefined){
        return true;
    }
    let rightSeg = expression.split(/&|\|/)
    let isAnd = expression.indexOf("&")!=-1;
    if(isAnd){
        for(let i=0;i<rightSeg.length;i++) {
            if(rights.indexOf(rightSeg[i])==-1) {
                return false;
            }
        }
        return true;
    } else{
        for(let i=0;i<rightSeg.length;i++) {
            if(rights.indexOf(rightSeg[i])!=-1) {
                return true;
            }
        }
        return false;
    }
}

export default function AuthConext(props) {
    const [user, setUser] = useState(null);
    const [checking, setChecking] = useState(false);
    const history=useHistory();

    const check=()=>{
        if(checking){
            return;
        }
        setChecking(true);
        mtop.get("/gateway/session")
            .then((data) => {
                setChecking(false);
                if (data.success) {
                    //username,usernick,rights
                    setUser(data.model)
                } else {
                    history.push(props.loginPath);
                }
            })
    }
    const logout=()=>{
        setChecking(true);
        setUser(null);
        mtop.get("/gateway/session/logout")
        .then((data)=>{
            setChecking(false);
            if (data.success) {
                history.push(props.loginPath);
            } else {
                message.error({content:data.desc,style:{marginTop:"45vh"}});
            }
        })
    }
    useEffect(()=>{
        mtop.setLogoutHandler(logout);
    },[])

    return (
        <Context.Provider value={{
            user: user,
            logout: logout,
            check: check,
            haveRights:(expression) =>{
                return haveRights(expression,user.rights);
            }
        }}>
            {
                checking ? (
                    <ProSkeleton type="list" pageHeader={true} />
                ) : props.children
            }
        </Context.Provider>
    )
}

export function useAuth() {
    return useContext(Context);
}
