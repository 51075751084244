import { Switch, Route } from 'react-router-dom';
import AuthChecker from './auth/AuthChecker';
import MainLayout from './layout/MainLayout';

import ProxyHttpPage from './page/ProxyHttpPage';
import ProxyPortPage from './page/ProxyPortPage';
import PageFrp from './page/PageFrp';
import Login from "./page/Login";

function App() {
  return (
    <div style={{ height: '100vh' }}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <AuthChecker>

                <Switch>
                  <Route path="/proxy/http">
                    <MainLayout>
                      <ProxyHttpPage />
                    </MainLayout>
                  </Route>
                  <Route path="/proxy/port">
                    <MainLayout>
                      <ProxyPortPage />
                    </MainLayout>
                  </Route>
                  <Route path="/frp">
                    <MainLayout>
                      <PageFrp />
                    </MainLayout>
                  </Route>
                  <Route path="/">
                    <MainLayout>
                    </MainLayout>
                  </Route>
                </Switch>
          </AuthChecker>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
